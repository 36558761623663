<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close"  aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="offer-content">
                                <h2>Please Login to Continue Shopping</h2>
                                <div class="media-body align-self-center text-center">
                                    <div class="buttons">
                                        <a [routerLink]="['/login']" (click)="modal.dismiss('Cross click')" class="view-cart btn btn-solid">Login</a>
                                        <a (click)="modal.dismiss('Cross click')" class="checkout btn btn-solid">Close</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->