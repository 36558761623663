import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../shared/services/order.service';
import { CommonModule } from '@angular/common';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-invoice',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './invoice.component.html',
  styleUrl: './invoice.component.scss'
})
export class InvoiceComponent implements OnInit{

  orderDetails: any = {};
  userDetails: any = {};
  shippingDetails: any = {}
  orderId: string = '';

  constructor(
    public authService: AuthService,
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const urlSegments = this.activatedRoute.snapshot.url;
    this.orderId = urlSegments[urlSegments.length - 1].path;
    this.orderService.orderGetDetails(this.orderId).subscribe({
      next: (res) => {
        console.log("Order: ", res)
        this.orderDetails = res
        this.orderDetails.order_items.forEach(item => {
          if (item.options) {
            try {
              item.options = JSON.parse(item.options);
            } catch (error) {
              console.error('Error parsing options for item', item, error);
              item.options = null;
            }
          }
        });
        try {
          this.shippingDetails = JSON.parse((res.courier).replace(/'/g, '"'));
          // console.log("Shipping: ", this.shippingDetails)
        } catch (error) {
          // console.error('Invalid JSON string', error);
        }
        this.authService.getCurrentUser().subscribe({
          next: (response) => {
            console.log("User: ", response)
            this.userDetails = response
          }
        })
      }, error: (err) => {
        console.log("Error: ", err)
      }
    })
  }

}
