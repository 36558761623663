
  <!-- invoice start -->
  <section class="theme-invoice-5 section-b-space">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 m-auto">
          <div class="invoice-wrapper">
            <div class="invoice-header">
              <img src="assets/invoice/images/bg4.jpg" class="img-fluid background-img" alt="">
              <div class="row">
                <div class="col-md-2 order-md-3 mb-md-0 mb-2">
                  <h2>invoice</h2>
                </div>
                <div class="col-lg-5 col-md-4 col-sm-6">
                  <div class="address-detail">
                    <div>
                      <h4 class="mb-2">
                        Address: {{ orderDetails.address.tag.length > 25 ? (orderDetails.address.tag | slice:0:25) + '...' : orderDetails.address.tag }}
                      </h4>
                      <h4 class="mb-2">{{ orderDetails.address.detail_address.length > 25 ? (orderDetails.address.detail_address | slice:0:25) + '...' : orderDetails.address.detail_address }}</h4>
                      <h4 class="mb-0">{{ orderDetails.address.postal_code }}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-sm-6">
                  <ul class="date-detail">
                    <li><span>customer: </span>
                      <h4>{{ userDetails.first_name.length > 25 ? (userDetails.first_name | slice:0:25) + '...' : userDetails.first_name }}</h4>
                    </li>
                    <li><span>email :</span>
                      <h4 style="text-transform: lowercase;">{{ userDetails.email.length > 25 ? (userDetails.email | slice:0:25) + '...' : userDetails.email }}</h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="invoice-body">
              <div class="header-panel">
                <ul>
                  <li>
                    <img src="assets/logo-main.png" class="img-fluid" alt="">
                  </li>
                  <li>
                    <i class="fa fa-map" aria-hidden="true"></i>
                    <div>
                      <h4>Printwave</h4>
                      <h4 class="mb-0">ID, 14350</h4>
                    </div>
                  </li>
                  <li>
                    <i class="fa fa-phone" aria-hidden="true"></i>
                    <h4 style="margin-bottom: 0px;">0811-8800-5200</h4>
                  </li>
                </ul>
              </div>
              <div class="table-responsive-md">
                <table class="table table-borderless mb-0">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">product</th>
                      <th scope="col">options</th>
                      <th scope="col">qty.</th>
                      <th scope="col">subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of orderDetails.order_items let  i = index">
                      <th scope="row">{{i+1}}</th>
                      <td>{{ item.product.title.length > 25 ? (item.product.title | slice:0:25) + '...' : item.product.title }}</td>
                      <td>
                        <div class="details-options">
                          <p *ngFor="let opt of item.options.option">{{opt.value.name}}</p>
                        </div>
                      </td>
                      <td>{{ item.quantity }}</td>
                      <td>Rp.{{ item.total_price }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2"></td>
                      <td class="font-bold text-dark" colspan="2">GRAND TOTAL</td>
                      <td class="font-bold text-theme">Rp. {{orderDetails.total_price}}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div>
                <p>Notes: {{ orderDetails.order_notes }}</p>
              </div>
            </div>
            <div class="invoice-footer text-end">
              <div class="authorise-sign">
                <p>Order ID: {{ orderDetails.order_id }}</p>
              </div>
              <div class="buttons">
                <a href="#" class="btn btn-solid rounded-2" onclick="window.print();">print</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- invoice end -->
