import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

const API_register = environment.base_url + 'register';
const API_login = environment.base_url + 'login';
const API_check = environment.base_url + 'token-check';
const API_current_user = environment.base_url + 'me'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isAuthenticated = false;
  public authSecretKey = 'x-access-token';

  constructor(private http: HttpClient, private router: Router) {
    this.isAuthenticated = !!localStorage.getItem(this.authSecretKey);
  }

  register(registerForm: any): Observable<any> {
    const body = JSON.stringify(registerForm);
    return this.http.post<any>(API_register, body, httpOptions);
  }
  
  login(loginCreds: { email: string; password: string }): Observable<any> {
    const body = JSON.stringify(loginCreds);
    return this.http.post<any>(API_login, body, httpOptions);
  }

  isAuthenticatedUser(): Observable<boolean> {
    var subject = new Subject<boolean>();
    this.http.get<any>(API_check).subscribe({
      error: (error) => {        
        localStorage.removeItem(this.authSecretKey);
        console.log('TEST TOKEN ERROR:', error.error.detail);
        subject.next(false);
      },
      next: (res) => {
        subject.next(true);
      },
    });
    return subject.asObservable();
  }

  logout(): void {
    localStorage.removeItem(this.authSecretKey);
    this.isAuthenticated = false;
    this.router.navigateByUrl('/home');
  }

  getCurrentUser() {
    return this.http.get<any>(API_current_user)
  }

  CurrentUserEdit(form: any) {
    const body = JSON.stringify(form)
    console.log(form)
    return this.http.patch<any>(API_current_user, body, httpOptions)
  }
}
