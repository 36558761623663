import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree => {

    const authService: AuthService = inject(AuthService)
    const router: Router = inject(Router)

  return new Observable<boolean>((obs) => { 
    authService.isAuthenticatedUser().subscribe((res: boolean) => {
      console.log('Auth Guard: ', res);
      if (res) {
        obs.next(true);
      } else {
        authService.logout();
        obs.next(false);
      }
    });
  });
};