import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  passwordView: boolean;

  public customPasswordMatching(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;
    
    return password === confirmPassword ? null : { passwordMismatchError: true };
  }

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        phone: ['', [Validators.required, Validators.pattern(/^0\d{9,13}$/)]],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]]        
      },
      { validators: this.customPasswordMatching.bind(this) }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  togglePasswordView() {
    this.passwordView = !this.passwordView;
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.registerForm);
    if (this.registerForm.invalid) {
      alert('Invalid Input')
      return;
    }
    if (this.registerForm.valid) {
      console.log(this.registerForm)
      this.authService.register(this.registerForm.value).subscribe({
        next: (data) => {
          console.log(data)
          if (data.error) {
            alert (
              'ERROR! ' + (data.error)
            )
            return
          }
          this.authService.login({email: this.registerForm.value.email, password: this.registerForm.value.password}).subscribe({
            next: (data) => {
              // console.log(data)
              this.authService.isAuthenticated = true;
              localStorage.setItem(this.authService.authSecretKey, data.accesstoken);
              this.router.navigateByUrl('/home');
            },
            error: (error) => {
              // console.log('ERROR:', error.error.detail);
              this.onReset();
              alert (
                'ERROR! ' + error.error.detail + ' Please try again'
              )
            },
          });
        },
      });
    }
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }
}