<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <img src="assets/images/icon/search.png" class="img-fluid" alt="">
        <i class="ti-search"></i>
      </div>
      <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
        <div> 
            <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
            <div class="overlay-content">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Search a Product" [(ngModel)]="searchInput" (keydown.enter)="onSearch(searchInput)">
                            </div>
                            <button type="submit" class="search-btn btn btn-primary" (click)="onSearch(searchInput)"><i class="fa fa-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </li>
    <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/shop/cart']">
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ cart.length }}</span>
      <ul class="show-div shopping-cart" *ngIf='!cart.length'>
        <h5>Your cart is currently empty.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='cart.length'>
        <li *ngFor="let product of cart">
          <div class="media">
            <a [routerLink]="['/shop/product/', product.title.replace(' ', '-')]">
              <img class="me-3" [src]="product?.images[0].image_url">
            </a>
            <div class="media-body">
              <a [routerLink]="['/shop/product/', product.title.replace(' ', '-')]">
                <h4>{{ product?.title }}</h4>
              </a>
              <h4>
                <span>
                  {{ (product?.total_price) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product.id)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a>
            <a [routerLink]="['/shop/checkout']" class="checkout">checkout</a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>