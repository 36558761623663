import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

@HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
    event.preventDefault();
    }
}

@HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    
    // Allow paste if it is numeric
    if (!/^\d+$/.test(pastedText)) {
    event.preventDefault(); // Block non-numeric characters
    }
}

@HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    // Replace any non-numeric characters on input
    input.value = input.value.replace(/[^0-9]/g, '');
}
}