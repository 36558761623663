<!-- header start -->
<header class="sticky" [ngClass]="class">
    <!-- [class.fixed]="stick && sticky" -->
    <div class="mobile-fix-option"></div>
    <div class="top-header top-header-dark2" *ngIf="topbar" style="background-color: #174586;">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="header-contact">
                        <ul>
                            <li style="color: white;">Welcome to Printwave</li>
                            <li><i class="fa fa-phone" style="color: #25D366;" aria-hidden="true"></i><a href="https://wa.me/+6281188005200" target="_blank" class="custom-link">WA Admin</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 text-end">
                    <ul class="header-dropdown">
                        <li></li>
                        <li class="onhover-dropdown mobile-account">
                            <i class="fa fa-user" style="color: white;" aria-hidden="true"></i> 
                                <span *ngIf="!isLoggedIn" style="color: white;">My Account</span>
                                <span *ngIf="isLoggedIn" style="color: white;"> {{user.first_name}} </span>
                            <ul class="onhover-show-div" *ngIf="!isLoggedIn">
                                <li>
                                    <a data-lng="en" [routerLink]="['/login']">
                                        Login
                                    </a>
                                </li>
                                <li>
                                    <a data-lng="es"  [routerLink]="['/register']">
                                        Register
                                    </a>
                                </li>
                            </ul>
                            <ul class="onhover-show-div" *ngIf="isLoggedIn">
                                <li>
                                    <a data-lng="en" [routerLink]="['/account/dashboard']">
                                        Dashboard
                                    </a>
                                </li>
                                <li>
                                    <a (click)="logout()">
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container layout3-menu asdfg">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu">
                    <div class="menu-left around-border">
                        <div class="main-menu-right">
                            <app-menu></app-menu>
                        </div>
                    </div>
                        <div class="brand-logo">
                            <a [routerLink]="['/home']">
                                <img [src]="themeLogo" alt="logo" height="50px">
                            </a>
                        </div>
                    <div class="menu-right pull-right">
                        <div>
                            <app-settings></app-settings>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header end -->