import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public url : any; 
  public openDashboard: boolean = false;
  activeUrl: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {  
    this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.url = event.url;
          }
    });
  }
  
  ToggleDashboard() {
    this.openDashboard = !this.openDashboard;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // Get the activated child route URL
        this.updateActiveButton();
      });
  }

  updateActiveButton() {
    let currentRoute = this.activatedRoute;
    
    // Traverse through the activated child routes to find the deepest one
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    currentRoute.url.subscribe(url => {
      this.activeUrl = url.map(segment => segment.path).join('/');
      // Now, you can use `this.activeUrl` to conditionally style your buttons
      // console.log('Current activated child URL:', this.activeUrl);
    });
  }
}
