<!-- tap to top -->
<div class="tap-wa top-cls" (click)="open()" 
	[ngStyle]="{ 'display': show ? 'block' : 'none' }">
  <a>
    <span style="display: inline-block; vertical-align: middle;">
      <svg class="unf-icon" viewBox="0 0 24 24" width="20" height="20" fill="#ffffff">
        <path d="M17 3.25H7A3.71 3.71 0 0 0 3.25 7v14a.76.76 0 0 0 .41.67.84.84 0 0 0 .34.08.74.74 0 0 0 .45-.15l3.8-2.85H17A3.71 3.71 0 0 0 20.75 15V7A3.71 3.71 0 0 0 17 3.25Zm-4 10.49H8a.75.75 0 1 1 0-1.5h5a.75.75 0 1 1 0 1.5Zm3-4H8a.75.75 0 0 1 0-1.5h8a.75.75 0 1 1 0 1.5Z"></path>
      </svg>
      Chat with us!
    </span>
  </a>
</div>
<!-- tap to top -->