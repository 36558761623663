import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../classes/product";

@Component({
  selector: 'app-cart-variation',
  templateUrl: './cart-variation.component.html',
  styleUrls: ['./cart-variation.component.scss']
})
export class CartVariationComponent implements OnInit, OnDestroy {

  @Input() direction: string = 'right'; // Default Direction Right

  public cart: any[] = [];

  constructor(public productService: ProductService) {
    this.productService.cartGet().subscribe((cartItems) => {
      this.productService.getProducts.subscribe((productItems) => {
        // console.log("Cart items: ", cartItems)
        // console.log("Products: ", res)
        let joinedItems = cartItems.filter(cart => productItems.some(productItem => productItem.id === cart.product))
          .map(cart => {
            const productItem = productItems.find(item => item.id === cart.product);
            return {
              ...cart,
              title: productItem?.title,
              images: productItem?.images,
            };
          })
          .sort((a, b) => a.id - b.id);
        // console.log("Joined Cart: ", joinedItems)
        this.cart = joinedItems
      });
    })
  }

  ngOnInit(): void {
    this.productService.OpenCart = false;
  }

  closeCart() {
    this.productService.OpenCart = false;
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount(this.cart);
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  ngOnDestroy(): void {
    this.closeCart();
  }

}
