<div class="media d-flex align-items-center">
    <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
             [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].image_url"  
             class="img-fluid lazy-loading">
    </a>
	<div class="media-body align-self-center">
		<div class="rating">
			<bar-rating [rate]="5" [readOnly]="true"></bar-rating>
		</div>
        <a [routerLink]="['/shop/product/', product.title.replace(' ', '-')]">
            <h6>{{ product.title | titlecase }}</h6>
        </a>
		<h4>
            {{ product?.price[0].value * currency?.price | currency:currency?.currency:'symbol' }}
        </h4>
    </div>
</div>