import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const state = {
  checkoutItems: JSON.parse(localStorage['checkoutItems'] || '[]')
}

const API_addresses = environment.base_url + 'addresses'
const API_courier = environment.base_url + 'rates'
const API_order = environment.base_url + 'orders'
const API_transactions = environment.base_url + 'transactions'
const API_placeOrder = environment.base_url + 'place-order'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private router: Router, private http: HttpClient) { }

  // Get Checkout Items
  public get checkoutItems(): Observable<any> {
    const itemsStream = new Observable(observer => {
      observer.next(state.checkoutItems);
      observer.complete();
    });
    return <Observable<any>>itemsStream;
  }

  orderGetDetails(orderId) {
    const url = API_order + '/' + orderId
    return this.http.get<any>(url);
  }

  orderGet() {
    return this.http.get<any>(API_order);
  }

  placeOrder(orderId: string, transactionId: number) {
    const body = JSON.stringify({order_id: orderId, transaction_id: transactionId})
    return this.http.post<any>(API_placeOrder, body, httpOptions);
  }

  generatePaymentToken(orderId: string, price: number) {
    const url = API_transactions + '/' + orderId
    const body = JSON.stringify({gross_amount: price})
    return this.http.post<any>(url, body, httpOptions);
  }

  orderCreate(orderForm: any) {
    console.log(orderForm)
    const body = JSON.stringify(orderForm)
    return this.http.post<any>(API_order, body, httpOptions);
  }

  addressDelete(id: number) {
    const url = API_addresses + '/' + id
    return this.http.delete<any>(url);
  }

  addressGet() {
    return this.http.get<any>(API_addresses);
  }

  addressCreate(addressForm) {
    const body = JSON.stringify(addressForm)
    return this.http.post<any>(API_addresses, body, httpOptions);
  }

  retrieveRates(courierForm) {
    const body = JSON.stringify(courierForm)
    // console.log("Courier Form", courierForm)
    return this.http.post<any>(API_courier, body, httpOptions)
  }

  // Create order
  public createOrder(product: any, details: any, orderId: any, amount: any) {
    var item = {
        shippingDetails: details,
        product: product,
        orderId: orderId,
        totalAmount: amount
    };
    state.checkoutItems = item;
    localStorage.setItem("checkoutItems", JSON.stringify(item));
    localStorage.removeItem("cartItems");
    this.router.navigate(['/shop/checkout/success', orderId]);
  }
  
}
