import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  passwordView: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', (Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"))],
      password: ['', Validators.required],
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  togglePasswordView() {
    this.passwordView = !this.passwordView;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    if (this.loginForm.valid) {
      // console.log(this.loginForm.value);
      this.authService.login(this.loginForm.value).subscribe({
        next: (data) => {
          // console.log(data)
          this.authService.isAuthenticated = true;
          localStorage.setItem(this.authService.authSecretKey, data.accesstoken);
          this.router.navigateByUrl('/home');
        },
        error: (error) => {
          console.log('ERROR:', error.error.detail);
          this.onReset();
          alert (
            'ERROR! ' + error.error.detail + ' Please try again'
          )
        },
      });
    }
  }

  onReset() {
    this.submitted = false;
    this.loginForm.reset();
  }

}
