import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-contact-pop',
  templateUrl: './contact-pop.component.html',
  styleUrls: ['./contact-pop.component.scss']
})
export class ContactPopComponent implements OnInit {
  
  public show: boolean = true;

  constructor(private viewScroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  open() {
    const number = '+6281188005200'
    const message = 'Halo min, saya tertarik dengan produk Printwave. Bisa dibantu untuk informasi lebih lanjut? Terima kasih!'
    const whatsappURL = `https://api.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  }

}
