import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  catList: any = []

  constructor(public productService: ProductService) { 
    this.productService.getProducts.subscribe(product => this.products = product);
    this.catList = Array.from(new Map(this.products.map(product => [product.category_details.name, product.category_details])).values());
  }

  ngOnInit(): void { }

  get filterbyCategory() {
    return this.catList
  }

}
