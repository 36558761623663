import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-delete-prompt',
  templateUrl: './delete-prompt.component.html',
  styleUrls: ['./delete-prompt.component.scss']
})
export class DeletePromptComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("deletePrompt", { static: false }) DeletePromptModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  private modalSubject: Subject<boolean> | null = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if(localStorage.getItem("deletePrompt") !== 'true')
       this.openModal();
    localStorage.setItem("deletePrompt", 'true');
  }

  openModal(): Observable<boolean> {
    this.modalSubject = new Subject<boolean>();
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.DeletePromptModal, { 
        size: 'lg',
        ariaLabelledBy: 'DeletePrompt-Modal',
        centered: true,
        windowClass: 'theme-modal newsletterm NewsLetterModal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        if (reason == 'accept') {
          this.modalSubject.next(true);
        }
        else {
          this.modalSubject.next(false);
        }
      });
    }
    return this.modalSubject.asObservable()
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}
