import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { filter, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public cart: any[] = [];
  public search: boolean = false;
  public searchInput = '';
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    private router: Router,
    public productService: ProductService
  ) {
    this.initItems()
  }

  ngOnInit(): void {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      // console.log('Page changed:', event.url);
      this.initItems()
      // You can add more logic here if needed.
    });
  }

  searchToggle(){
    this.search = !this.search;
  }

  initItems() {
    this.productService.cartGet().subscribe((cartItems) => {
      this.productService.getProducts.subscribe((productItems) => {
        // console.log("Cart items: ", cartItems)
        // console.log("Products: ", res)
        let joinedItems = cartItems.filter(cart => productItems.some(productItem => productItem.id === cart.product))
          .map(cart => {
            const productItem = productItems.find(item => item.id === cart.product);
            return {
              ...cart,
              title: productItem?.title,
              images: productItem?.images,
            };
          })
          .sort((a, b) => a.id - b.id);
        // console.log("Joined Cart: ", joinedItems)
        this.cart = joinedItems
      });
    })
  }

  onSearch(term: string) {
    const targetUrl = `/shop/collection?search=${term}`
    if (term) {
      // console.log('Search term:', term);
      this.router.navigateByUrl('/shop', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/shop/collection'], { queryParams: { search: term } });
      });
      this.search = !this.search;
    }
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount(this.cart);
  }

  removeItem(id: any) {
    this.productService.removeCartItem(id).subscribe((res) => {
      // console.log("Product ", id, " removed")
      this.productService.cartGet().subscribe((cartItems) => {
        this.productService.getProducts.subscribe((productItems) => {
          // console.log("Cart items: ", cartItems)
          // console.log("Products: ", res)
          let joinedItems = cartItems.filter(cart => productItems.some(productItem => productItem.id === cart.product))
            .map(cart => {
              const productItem = productItems.find(item => item.id === cart.product);
              return {
                ...cart,
                title: productItem?.title,
                images: productItem?.images,
              };
            });
          // console.log("Joined Cart: ", joinedItems)
          this.cart = joinedItems
        });
      })
    })
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

}
